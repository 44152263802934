import React, { Component } from 'react';
import { Gmaps } from './gmaps';
import { Col } from 'reactstrap';
import { Row } from 'reactstrap';
import { Container } from 'reactstrap';
import { Button } from '@mui/material';
import { Contact } from './contact';
import { Animals } from './animals';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <>
                <Row xs="1" sm="1" md="2" lg="2" className="center">
                    <Col>
                        <br />
                        <Button variant="contained" href="https://issuu.com/cattledesign/docs/f24simangussolutions_salebook_final_web" target="new">ISSUU Catalog</Button>
                    </Col>
                    <Col>
                        <br />
                        <Button variant="contained" href="../documents/catalog.pdf" target="new">Download Catalog</Button>
                    </Col>
                </Row>
                <Row xl="1">
                    <Col>
                        <Contact />
                        <div class="divider"><div class='line'>&nbsp; </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={1}>
                    </Col>
                    <Col xs={10}>
                        <Animals />
                    </Col>

                    <Col xl={1}>

                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col xs={3}>
                    </Col>
                    <Col xs={1}>
                        <img src="../images/IMG_3007.GIF" class="assoclogo" />
                    </Col>
                    <Col xs={1}>
                    </Col>
                    <Col xs={1}>
                        <img src="../images/IMG_3008.PNG" class="assoclogo" />
                    </Col>
                    <Col xs={1}>
                    </Col>
                    <Col xs={1}>
                        <img src="../images/IMG_3010.JPG" class="assoclogo" />
                    </Col>
                    <Col xs={1}>
                    </Col>

                </Row>

                <br />
                <br />

            </>);
    }
}
