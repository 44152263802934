import React, { Component } from 'react';
import './animal.css';
import { Container, Row, Col } from 'reactstrap';
import { Button } from '@mui/material';
import { Logo } from './logos';

export default class Animal extends Component {

    constructor(props) {
        super();
        let dsc = props.animal.desc;
        if (dsc.length < 255) {
            for (var i = 0; i < 255; i++) {
                dsc += '\xa0 ';
            }
        }
        this.state = {
            count: props.count,
            outofsale: props.animal.outofsale === undefined ? false : props.animal.outofsale,
            id: props.animal.id,
            lot: props.animal.lot,
            icon: props.animal.icon,
            youtube: props.animal.youtube,
            physicalDesc: props.animal.physicalDesc,
            polled: props.animal.polled,
            sm: props.animal.sm,
            an: props.animal.an,
            sh: props.animal.sh,
            asa: props.animal.asa,
            bd: props.animal.bd,
            issuuPage: props.animal.issuuPage,
            data: {
                ce: {
                    epd: props.animal.data.ce.epd,
                    rank: props.animal.data.ce.rank
                },
                bw: {
                    epd: props.animal.data.bw.epd,
                    rank: props.animal.data.bw.rank
                },
                ww: {
                    epd: props.animal.data.ww.epd,
                    rank: props.animal.data.ww.rank
                },
                yw: {
                    epd: props.animal.data.yw.epd,
                    rank: props.animal.data.yw.rank
                },
                mce: {
                    epd: props.animal.data.mce.epd,
                    rank: props.animal.data.mce.rank
                },
                milk: {
                    epd: props.animal.data.milk.epd,
                    rank: props.animal.data.mce.rank
                },
                mww: {
                    epd: props.animal.data.mww.epd,
                    rank: props.animal.data.mww.rank
                },
                yg: {
                    epd: props.animal.data.yg.epd,
                    rank: props.animal.data.yg.rank
                },
                marb: {
                    epd: props.animal.data.marb.epd,
                    rank: props.animal.data.marb.rank
                },
                bf: {
                    epd: props.animal.data.bf.epd,
                    rank: props.animal.data.bf.rank
                },
                rea: {
                    epd: props.animal.data.rea.epd,
                    rank: props.animal.data.rea.rank
                },
                api: {
                    epd: props.animal.data.api.epd,
                    rank: props.animal.data.api.rank
                },
                ti: {
                    epd: props.animal.data.ti.epd,
                    rank: props.animal.data.ti.rank
                }
            },
            adjBW: props.animal.adjBW,
            adj205: props.animal.adj205,
            adj365: props.animal.adj365,
            sc: props.animal.sc,
            desc: dsc,
            logos: props.animal.logos
        }
    };

    render() {
        let output;
        if (this.state.sc !== '') {
            if (this.state.count % 2 !== 0) {
                output = <><Row sm="1" md="1" lg="1" xl="2">
                    <Col className="center">
                        {this.state.youtube !== "" ?
                            <><iframe width="560" height="315" src={"https://www.youtube.com/embed/" + this.state.youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></>
                            :
                            <> <iframe width="560" height="315" src="https://via.placeholder.com/560x315?text=Video+Coming+Soon" title="YouTube video player" frameBorder="0"></iframe>
                            </>}
                        <br /><Button variant="contained" href={"https://issuu.com/cattledesign/docs/f24simangussolutions_salebook_final_web/" + this.state.issuuPage} target="new">View Lot {this.state.lot} on ISSUU</Button>
                    </Col>
                    <Col>
                        <table class='mainTable'>
                            <tbody>
                                <tr class='idRow'>
                                    <td rowSpan="2" class="animalLot">
                                        {this.state.lot}
                                    </td>
                                    <td class='animalName' colSpan="3">
                                        {this.state.id}
                                        <span class="oos">{this.state.outofsale == false ? "" : "OUT OF SALE"}</span>
                                    </td><td>
                                    </td>
                                    <td>
                                        <img class='icon' src={"../images/" + this.state.icon + ".png"} />
                                    </td>
                                </tr>
                                <tr class='dr2'>
                                    <td>
                                        {this.state.physicalDesc}<br />
                                        ASA #{this.state.asa}
                                    </td>
                                    <td>
                                        {this.state.polled}<br />
                                        {this.state.id}
                                    </td>
                                    <td colSpan="3" class="sman">
                                        {this.state.sm} SM {this.state.an} AN {this.state.sh === '' ? '' : `${this.state.sh} SH`}<br />
                                        {this.state.bd}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="dt1">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>CE</th>
                                    <th>BW</th>
                                    <th>WW</th>
                                    <th>YW</th>
                                    <th>MCE</th>
                                    <th>MILK</th>
                                    <th>MWW</th>
                                </tr>
                                <tr>
                                    <td>EPD</td>
                                    <td>{this.state.data.ce.epd}</td>
                                    <td>{this.state.data.bw.epd}</td>
                                    <td>{this.state.data.ww.epd}</td>
                                    <td>{this.state.data.yw.epd}</td>
                                    <td>{this.state.data.mce.epd}</td>
                                    <td>{this.state.data.milk.epd}</td>
                                    <td>{this.state.data.mww.epd}</td>
                                </tr>
                                <tr class="rankRow">
                                    <td>Rank</td>
                                    <td>{this.state.data.ce.rank}</td>
                                    <td>{this.state.data.bw.rank}</td>
                                    <td>{this.state.data.ww.rank}</td>
                                    <td>{this.state.data.yw.rank}</td>
                                    <td>{this.state.data.mce.rank}</td>
                                    <td>{this.state.data.milk.rank}</td>
                                    <td>{this.state.data.mww.rank}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="dt1">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>YG</th>
                                    <th>MARB</th>
                                    <th>BF</th>
                                    <th>REA</th>
                                    <th>API</th>
                                    <th>TI</th>
                                </tr>
                                <tr>
                                    <td>EPD</td>
                                    <td>{this.state.data.yg.epd}</td>
                                    <td>{this.state.data.marb.epd}</td>
                                    <td>{this.state.data.bf.epd}</td>
                                    <td>{this.state.data.rea.epd}</td>
                                    <td>{this.state.data.api.epd}</td>
                                    <td>{this.state.data.ti.epd}</td>
                                </tr>
                                <tr class="rankRow">
                                    <td>Rank</td>
                                    <td>{this.state.data.yg.rank}</td>
                                    <td>{this.state.data.marb.rank}</td>
                                    <td>{this.state.data.bf.rank}</td>
                                    <td>{this.state.data.rea.rank}</td>
                                    <td>{this.state.data.api.rank}</td>
                                    <td>{this.state.data.ti.rank}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="dt2">
                            <tbody>
                                <tr>
                                    <td>
                                        <span class="header">Adj. BW (ratio)</span><br />
                                        <span class="data">{this.state.adjBW}</span>
                                    </td>
                                    <td>
                                        <span class="header">Adj. 205 (ratio)</span><br />
                                        <span class="data">{this.state.adj205}</span>
                                    </td>
                                    <td>
                                        <span class="header">Adj. 365 (ratio)</span><br />
                                        <span class="data">{this.state.adj365}</span>
                                    </td>
                                    <td>
                                        <span class="header">SC (ratio)</span><br />
                                        <span class="data">{this.state.sc}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="dt3">
                            <tbody>
                                <tr>
                                    <td class="desc">{this.state.desc}</td>
                                    <td class="logo">
                                        {
                                            this.state.logos.map((logo) =>
                                                <Logo logo={logo} />)
                                        }

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                </>
            }
            else {
                output = <>
                    <Row sm="1" md="1" lg="1" xl="2">
                        <Col>
                            <table class='mainTable'>
                                <tbody>
                                    <tr class='idRow'>
                                        <td rowSpan="2" class="animalLot">
                                            {this.state.lot}
                                        </td>
                                        <td class='animalName' colSpan="3">
                                            {this.state.id}

                                            <span class="oos">{this.state.outofsale == false ? "" : "OUT OF SALE"}</span></td>
                                        <td></td>
                                        <td>
                                            <img class='icon' src={"../images/" + this.state.icon + ".png"} />
                                        </td>
                                    </tr>
                                    <tr class='dr2'>
                                        <td>
                                            {this.state.physicalDesc}<br />
                                            ASA #{this.state.asa}
                                        </td>
                                        <td>
                                            {this.state.polled}<br />
                                            {this.state.id}
                                        </td>
                                        <td colSpan="3" class="sman">
                                            {this.state.sm} SM {this.state.an} AN<br />
                                            {this.state.bd}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="dt1">
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th>CE</th>
                                        <th>BW</th>
                                        <th>WW</th>
                                        <th>YW</th>
                                        <th>MCE</th>
                                        <th>MILK</th>
                                        <th>MWW</th>
                                    </tr>
                                    <tr>
                                        <td>EPD</td>
                                        <td>{this.state.data.ce.epd}</td>
                                        <td>{this.state.data.bw.epd}</td>
                                        <td>{this.state.data.ww.epd}</td>
                                        <td>{this.state.data.yw.epd}</td>
                                        <td>{this.state.data.mce.epd}</td>
                                        <td>{this.state.data.milk.epd}</td>
                                        <td>{this.state.data.mww.epd}</td>
                                    </tr>
                                    <tr class="rankRow">
                                        <td>Rank</td>
                                        <td>{this.state.data.ce.rank}</td>
                                        <td>{this.state.data.bw.rank}</td>
                                        <td>{this.state.data.ww.rank}</td>
                                        <td>{this.state.data.yw.rank}</td>
                                        <td>{this.state.data.mce.rank}</td>
                                        <td>{this.state.data.milk.rank}</td>
                                        <td>{this.state.data.mww.rank}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="dt1">
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th>YG</th>
                                        <th>MARB</th>
                                        <th>BF</th>
                                        <th>REA</th>
                                        <th>API</th>
                                        <th>TI</th>
                                    </tr>
                                    <tr>
                                        <td>EPD</td>
                                        <td>{this.state.data.yg.epd}</td>
                                        <td>{this.state.data.marb.epd}</td>
                                        <td>{this.state.data.bf.epd}</td>
                                        <td>{this.state.data.rea.epd}</td>
                                        <td>{this.state.data.api.epd}</td>
                                        <td>{this.state.data.ti.epd}</td>
                                    </tr>
                                    <tr class="rankRow">
                                        <td>Rank</td>
                                        <td>{this.state.data.yg.rank}</td>
                                        <td>{this.state.data.marb.rank}</td>
                                        <td>{this.state.data.bf.rank}</td>
                                        <td>{this.state.data.rea.rank}</td>
                                        <td>{this.state.data.api.rank}</td>
                                        <td>{this.state.data.ti.rank}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="dt2">
                                <tbody>
                                    <tr>
                                        <td>
                                            <span class="header">Adj. BW (ratio)</span><br />
                                            <span class="data">{this.state.adjBW}</span>
                                        </td>
                                        <td>
                                            <span class="header">Adj. 205 (ratio)</span><br />
                                            <span class="data">{this.state.adj205}</span>
                                        </td>
                                        <td>
                                            <span class="header">Adj. 365 (ratio)</span><br />
                                            <span class="data">{this.state.adj365}</span>
                                        </td>
                                        <td>
                                            <span class="header">SC (ratio)</span><br />
                                            <span class="data">{this.state.sc}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="dt3">
                                <tbody>
                                    <tr>
                                        <td class="desc">{this.state.desc}</td>
                                        <td class="logo">
                                            {
                                                this.state.logos.map((logo) =>
                                                    <Logo logo={logo} />)
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col className="center">
                            {this.state.youtube !== "" ?
                                <><iframe width="560" height="315" src={"https://www.youtube.com/embed/" + this.state.youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></>
                                :
                                <> <iframe width="560" height="315" src="https://via.placeholder.com/560x315?text=Video+Coming+Soon" title="YouTube video player" frameBorder="0"></iframe>
                                </>}
                            <br /><Button variant="contained" href={"https://issuu.com/cattledesign/docs/f23simangussolutions_salebook_finalweb/" + this.state.issuuPage} target="new">View Lot {this.state.lot} on ISSUU</Button>
                        </Col>
                    </Row>
                </>
            }
        }
        else {
            if (this.state.count % 2 !== 0) {
                output = <><Row sm="1" md="1" lg="1" xl="2">
                    <Col className="center">
                        {this.state.youtube !== "" ?
                            <><iframe width="560" height="315" src={"https://www.youtube.com/embed/" + this.state.youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></>
                            :
                            <> <iframe width="560" height="315" src="https://via.placeholder.com/560x315?text=Video+Not+Available" title="YouTube video player" frameBorder="0"></iframe>
                            </>}
                        <br /><Button variant="contained" href={"https://issuu.com/cattledesign/docs/f23simangussolutions_salebook_finalweb/" + this.state.issuuPage} target="new">View Lot {this.state.lot} on ISSUU</Button>
                    </Col>
                    <Col>
                        <table class='mainTable'>
                            <tbody>
                                <tr class='idRow'>
                                    <td rowSpan="2" class="animalLot">
                                        {this.state.lot}
                                    </td>
                                    <td class='animalName' colSpan="3">
                                        {this.state.id}
                                        <span class="oos">{this.state.outofsale == false ? "" : "OUT OF SALE"}</span>
                                    </td>
                                    <td></td>
                                    <td>
                                        <img class='icon' src={"../images/" + this.state.icon + ".png"} />
                                    </td>
                                </tr>
                                <tr class='dr2'>
                                    <td>
                                        {this.state.physicalDesc}<br />
                                        ASA #{this.state.asa}
                                    </td>
                                    <td>
                                        {this.state.polled}<br />
                                        {this.state.id}
                                    </td>
                                    <td colSpan="3" class="sman">
                                        {this.state.sm} SM {this.state.an} AN<br />
                                        {this.state.bd}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="dt1">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>CE</th>
                                    <th>BW</th>
                                    <th>WW</th>
                                    <th>YW</th>
                                    <th>MCE</th>
                                    <th>MILK</th>
                                    <th>MWW</th>
                                </tr>
                                <tr>
                                    <td>EPD</td>
                                    <td>{this.state.data.ce.epd}</td>
                                    <td>{this.state.data.bw.epd}</td>
                                    <td>{this.state.data.ww.epd}</td>
                                    <td>{this.state.data.yw.epd}</td>
                                    <td>{this.state.data.mce.epd}</td>
                                    <td>{this.state.data.milk.epd}</td>
                                    <td>{this.state.data.mww.epd}</td>
                                </tr>
                                <tr class="rankRow">
                                    <td>Rank</td>
                                    <td>{this.state.data.ce.rank}</td>
                                    <td>{this.state.data.bw.rank}</td>
                                    <td>{this.state.data.ww.rank}</td>
                                    <td>{this.state.data.yw.rank}</td>
                                    <td>{this.state.data.mce.rank}</td>
                                    <td>{this.state.data.milk.rank}</td>
                                    <td>{this.state.data.mww.rank}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="dt1">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>YG</th>
                                    <th>MARB</th>
                                    <th>BF</th>
                                    <th>REA</th>
                                    <th>API</th>
                                    <th>TI</th>
                                </tr>
                                <tr>
                                    <td>EPD</td>
                                    <td>{this.state.data.yg.epd}</td>
                                    <td>{this.state.data.marb.epd}</td>
                                    <td>{this.state.data.bf.epd}</td>
                                    <td>{this.state.data.rea.epd}</td>
                                    <td>{this.state.data.api.epd}</td>
                                    <td>{this.state.data.ti.epd}</td>
                                </tr>
                                <tr class="rankRow">
                                    <td>Rank</td>
                                    <td>{this.state.data.yg.rank}</td>
                                    <td>{this.state.data.marb.rank}</td>
                                    <td>{this.state.data.bf.rank}</td>
                                    <td>{this.state.data.rea.rank}</td>
                                    <td>{this.state.data.api.rank}</td>
                                    <td>{this.state.data.ti.rank}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="dt2">
                            <tbody>
                                <tr>
                                    <td>
                                        <span class="header">Adj. BW (ratio)</span><br />
                                        <span class="data">{this.state.adjBW}</span>
                                    </td>
                                    <td>
                                        <span class="header">Adj. 205 (ratio)</span><br />
                                        <span class="data">{this.state.adj205}</span>
                                    </td>
                                    <td>
                                        <span class="header">Adj. 365 (ratio)</span><br />
                                        <span class="data">{this.state.adj365}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="dt3">
                            <tbody>
                                <tr>
                                    <td class="desc">{this.state.desc}</td>
                                    <td class="logo">  {
                                        this.state.logos.map((logo) =>
                                            <Logo logo={logo} />)
                                    }</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                </>
            }
            else {
                output = <>
                    <Row sm="1" md="1" lg="1" xl="2">
                        <Col>
                            <table class='mainTable'>
                                <tbody>
                                    <tr class='idRow'>
                                        <td rowSpan="2" class="animalLot">
                                            {this.state.lot}
                                        </td>
                                        <td class='animalName' colSpan="3">
                                            {this.state.id}
                                            <span class="oos">{this.state.outofsale == false ? "" : "OUT OF SALE"}</span>
                                        </td>
                                        <td></td>
                                        <td>
                                            <img class='icon' src={"../images/" + this.state.icon + ".png"} />
                                        </td>
                                    </tr>
                                    <tr class='dr2'>
                                        <td>
                                            {this.state.physicalDesc}<br />
                                            ASA #{this.state.asa}
                                        </td>
                                        <td>
                                            {this.state.polled}<br />
                                            {this.state.id}
                                        </td>
                                        <td colSpan="3" class="sman">
                                            {this.state.sm} SM {this.state.an} AN<br />
                                            {this.state.bd}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="dt1">
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th>CE</th>
                                        <th>BW</th>
                                        <th>WW</th>
                                        <th>YW</th>
                                        <th>MCE</th>
                                        <th>MILK</th>
                                        <th>MWW</th>
                                    </tr>
                                    <tr>
                                        <td>EPD</td>
                                        <td>{this.state.data.ce.epd}</td>
                                        <td>{this.state.data.bw.epd}</td>
                                        <td>{this.state.data.ww.epd}</td>
                                        <td>{this.state.data.yw.epd}</td>
                                        <td>{this.state.data.mce.epd}</td>
                                        <td>{this.state.data.milk.epd}</td>
                                        <td>{this.state.data.mww.epd}</td>
                                    </tr>
                                    <tr class="rankRow">
                                        <td>Rank</td>
                                        <td>{this.state.data.ce.rank}</td>
                                        <td>{this.state.data.bw.rank}</td>
                                        <td>{this.state.data.ww.rank}</td>
                                        <td>{this.state.data.yw.rank}</td>
                                        <td>{this.state.data.mce.rank}</td>
                                        <td>{this.state.data.milk.rank}</td>
                                        <td>{this.state.data.mww.rank}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="dt1">
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th>YG</th>
                                        <th>MARB</th>
                                        <th>BF</th>
                                        <th>REA</th>
                                        <th>API</th>
                                        <th>TI</th>
                                    </tr>
                                    <tr>
                                        <td>EPD</td>
                                        <td>{this.state.data.yg.epd}</td>
                                        <td>{this.state.data.marb.epd}</td>
                                        <td>{this.state.data.bf.epd}</td>
                                        <td>{this.state.data.rea.epd}</td>
                                        <td>{this.state.data.api.epd}</td>
                                        <td>{this.state.data.ti.epd}</td>
                                    </tr>
                                    <tr class="rankRow">
                                        <td>Rank</td>
                                        <td>{this.state.data.yg.rank}</td>
                                        <td>{this.state.data.marb.rank}</td>
                                        <td>{this.state.data.bf.rank}</td>
                                        <td>{this.state.data.rea.rank}</td>
                                        <td>{this.state.data.api.rank}</td>
                                        <td>{this.state.data.ti.rank}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="dt2">
                                <tbody>
                                    <tr>
                                        <td>
                                            <span class="header">Adj. BW (ratio)</span><br />
                                            <span class="data">{this.state.adjBW}</span>
                                        </td>
                                        <td>
                                            <span class="header">Adj. 205 (ratio)</span><br />
                                            <span class="data">{this.state.adj205}</span>
                                        </td>
                                        <td>
                                            <span class="header">Adj. 365 (ratio)</span><br />
                                            <span class="data">{this.state.adj365}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="dt3">
                                <tbody>
                                    <tr>
                                        <td class="desc">{this.state.desc}</td>
                                        <td class="logo">  {
                                            this.state.logos.map((logo) =>
                                                <Logo logo={logo} />)
                                        }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col className="center">
                            {this.state.youtube !== "" ?
                                <><iframe width="560" height="315" src={"https://www.youtube.com/embed/" + this.state.youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></>
                                :
                                <> <iframe width="560" height="315" src="https://via.placeholder.com/560x315?text=Video+Not+Available" title="YouTube video player" frameBorder="0"></iframe>
                                </>}
                            <br /><Button variant="contained" href={"https://issuu.com/cattledesign/docs/f23simangussolutions_salebook_finalweb/" + this.state.issuuPage} target="new">View Lot {this.state.lot} on ISSUU</Button>
                        </Col>
                    </Row>
                </>
            }
        }
        return (
            <>
                <div class="row animal">
                    {output}
                </div>
            </>
        );
    };
}